import React, {useMemo, useState} from 'react';
import './App.css';
import importedContent from './data/new.content.json';

const versionA = '1.0.5';
const versionB = '1.0.7.alpha-718a';

function App() {
    // @ts-ignore
    const url = new URL(document.location);
    const {searchParams} = url;
    const currentParam = searchParams.get('current') || '0';

    const [current, setCurrent] = useState(parseInt(currentParam, 10));
    const [includeCandu, setIncludeCandu] = useState(true);

    const content = useMemo(() => {
        if (includeCandu) {
            return importedContent;
        }
        return importedContent.filter((c) => {
            return c.organization_id !== 1
        })
    }, [importedContent, includeCandu])

    const handleChange = (id: number) => {
        setCurrent(id);
        // @ts-ignore
        window.history.pushState({}, null, '/?current=' + id);
    }

    const next = () => {
        if (current < content.length - 1) {
            handleChange(current + 1);
        } else {
            handleChange(0);
        }
    }

    const previous = () => {
        if (current > 0) {
            handleChange(current - 1);
        } else {
            handleChange(content.length - 1);
        }
    }

    const shuffle = () => {
        handleChange(Math.floor(Math.random() * content.length));
    }

    const toggleIncludeCandu = () => {
        setIncludeCandu(!includeCandu);
    }

    const c = content[current];

    if (!c) {
        return (<h1>Out of bounds</h1>);
    }

    return (
        <div className="App">
            <div className="Head">
                <div className="Nav">
                    <button onClick={previous}>Previous</button>
                    <button onClick={shuffle}>Shuffle</button>
                    <button onClick={next}>Next</button>
                </div>
                <div className="ToggleContainer">
                    <label htmlFor="#include-candu">
                        Include Candu on Candu
                        <input id="#include-candu" type="checkbox" checked={includeCandu} onChange={toggleIncludeCandu} />
                    </label>
                </div>
                <div className="Current">
                    {current} - Org ID {c.organization_id} -
                    <a href={"https://app.candu.ai/workspaces/" + c.app_slug + "/editor/" + c.content_id + "/content/Everyone"}>{c.slug}</a>
                </div>
            </div>
            <div className="Preview">
                <div className="PreviewFrame">
                    <h4>{versionA}</h4>
                    <iframe
                        src={"./preview.html?slug=" + c.slug + '&token=' + c.client_token + '&version=' + versionA}></iframe>
                </div>
                <div className="PreviewFrame">
                    <h4>{versionB}</h4>
                    <iframe
                        src={"./preview.html?slug=" + c.slug + '&token=' + c.client_token + '&version=' + versionB + '&enableCssStylesheets=true'}></iframe>
                </div>
            </div>
        </div>
    );
}

export default App;
